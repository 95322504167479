.searchReminder {
    width:500px;
    border: 1.5px solid rgb(242, 242, 242);
    border-radius: 100px;
    height:45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left:30px;
    padding-right:5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.filter {
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px
}

.searchFiltersIcon {
    background-color: #EB4E5F;
    padding:7px;
    border-radius: 50%;
}

.line {
    border-top:1px solid rgb(230, 229, 229);
    margin-bottom: 0px;
}

.line2 {
    border-top:0.5px solid rgb(230, 230, 230);
    margin: 30px 0px;
}


.rentalsContent {
    display: flex;
    justify-content: start;
    height: calc(100vh - 135px);
}

.rentalsContentL {
    width: 50%;
    padding:30px;
    height: calc(100vh - 150px);
    overflow: scroll;
}

.rentalsContentR {
    width:50%;
}

.rentalDiv {
    display: flex;
    justify-content: space-around;
}

.rentalDivH {
    animation: mymove 3s;
    border-radius: 20px;
    display: flex;
    justify-content: start;
}


@keyframes mymove {
    from {background-color: #dddddd;}
    to {background-color: #dddddd00;}
  }
.rentalImg {
    height:200px;
    width:300px;
    border-radius:20px;
    margin-right:20px; 
}

.rentalInfo {
    padding:10px;
    width: 100%;
}

.rentalTitle {
    font-size: 23px;
    margin-bottom: 15px;;
}

.rentalDesc {
    color: gray;
}

.bottomButton {
    margin-top:50px;
    justify-content: space-between;
    display: flex;
    width:100%;
    align-items: center;
}

.price {
    display:flex;
    justify-content: end;
    gap:5px;
    color:#808080;
    font-size: 12px;
}
