.addFields {
    width:900px;
    border: 1.5px solid rgb(242, 242, 242);
    background-color: white;
    height:65px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

